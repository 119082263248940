<template>
    <div class="delete-users">
        <div class="delete-users-text">Upload CSV file containing user data to be deleted.</div>
        <div class="m-b-1">
            <anchor-link
                href="/templates/delete-users-csv-template.csv"
                left-icon="download-general"
                class="download-tempalte-link"
                download="delete-users-csv-template.csv"
            >
                Download CSV template
            </anchor-link>
        </div>

        <FileUpload
            :error="fileUploadError"
            :drag-enabled="false"
            :disabled="false"
            :accepted-content-type="'.csv'"
            :accepted-extensions="['csv']"
            :file-name="selectedFile ? selectedFile.name : ''"
            :upload-progress="uploadProgress"
            :is-uploading="isUploading"
            show-clear-icon
            show-upload-progress
            has-margin-bottom
            @changed="onFileChanged"
            @onClearImage="onClearFile"
        />

        <Button
            class="delete-users__button"
            left-icon="file-upload-arrow"
            has-border
            :disabled="uploadButtonIsDisabled"
            @click="startUploading"
        >
            Upload
        </Button>

        <Dialog
            show-confirm-button
            confirm-button-text="Leave"
            close-button-text="Continue Uploading"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>Upload in progress</template>
            <template #body>Are you sure you want to leave?</template>
        </Dialog>
    </div>
</template>
<script>
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import FileUpload from '@/components/ui/FileUpload';
import AnchorLink from '@/components/ui/Link';
import Dialog from '@/components/ui/Dialog';
import Button from '@/components/ui/Button';
import Storage from '@aws-amplify/storage';
import { ToastType } from '@/plugins/toaster';

export default {
    name: 'DeleteUsers',
    components: {
        FileUpload,
        AnchorLink,
        Dialog,
        Button,
    },
    mixins: [LeavingDialogMixin],
    data() {
        return {
            selectedFile: null,
            isUploading: false,
            leaveDialogIsOpen: false,
            uploadProgress: 0,
            fileUploadError: '',
        };
    },
    computed: {
        uploadButtonIsDisabled() {
            return this.selectedFile === null || this.isUploading;
        },
        hasUnsavedChanges() {
            return this.selectedFile || this.isUploading;
        },
    },
    methods: {
        onFileChanged(file) {
            this.selectedFile = file;
        },
        onClearFile() {
            this.selectedFile = null;
        },
        async startUploading() {
            this.isUploading = true;

            const progressCallback = (progress) => {
                this.uploadProgress = Math.round((progress.loaded / progress.total) * 100);
            };

            try {
                await Storage.put(this.selectedFile.name, this.selectedFile, {
                    bucket: process.env.VUE_APP_DELETE_USERS_BUCKET,
                    region: 'eu-west-1',
                    contentType: this.selectedFile.type,
                    customPrefix: {
                        public: '',
                    },
                    progressCallback,
                });
                this.isUploading = false;
                this.$toaster.add('File uploaded successfully', {
                    type: ToastType.Success,
                });
            } catch (error) {
                this.isUploading = false;
                this.fileUploadError = 'Unsuccessful upload. There was an error, please try again.';
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.download-tempalte-link {
    padding: 0;
}

.delete-users {
    width: 100%;
    max-width: 600px;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    &-text {
        margin-top: $form-group-margin-top;
        font-family: $font-family-default;
        font-size: $font-size-sm;
        font-weight: $font-weight-default;
    }
    &__button {
        margin-top: $form-group-margin-top;
        align-self: flex-end;
    }
}
</style>
