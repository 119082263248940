<template>
    <div class="user-management">
        <router-view :nav-is-opened="navIsOpened"></router-view>

        <FullPageLoadingSpinner :show="$wait.is(LoadingFlag.UserGet)" />

        <PageHeading :breadcrumbs="breadcrumbs" title="User Management" />

        <STabs :options="{ useUrlFragment: true, useCaching: false }">
            <STab v-if="showInviteAdminTab" name="CMS Users">
                <div class="admin-toolbar m-b-3">
                    <Button
                        type="button"
                        has-border
                        size="small"
                        @click="showInviteAdminDialog = true"
                    >
                        Invite CMS user
                    </Button>
                </div>

                <TableAdmin
                    :users="adminUsers"
                    :user-groups="adminUserGroups"
                    @load-user="onLoadUser"
                    @save-user="onSaveUser"
                />
            </STab>

            <STab name="Platform Users">
                <DeleteUsers />
            </STab>
        </STabs>

        <Dialog
            :show-footer="false"
            :is-dialog-visible="showInviteAdminDialog"
            @close="showInviteAdminDialog = false"
        >
            <template #header>Invite CMS User</template>
            <template #body>
                <InviteAdmin
                    :is-processing-invite="isProcessingInvite"
                    @invite-admin="onInviteAdmin"
                />
            </template>
        </Dialog>
    </div>
</template>

<script>
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { Breadcrumb } from '@/store/models/breadcrumb.dto';
import TableAdmin from '@/components/Tables/TableAdmin';
import PageHeading from '@/components/ui/PageHeading';
import DeleteUsers from '@/components/DeleteUsers';
import InviteAdmin from '@/components/InviteAdmin';
import { STabs, STab } from '@/components/Tabs';
import { ToastType } from '@/plugins/toaster';
import Button from '@/components/ui/Button';
import Dialog from '@/components/ui/Dialog';
import { mapActions } from 'vuex';
import config from '@/config';
import Vue from 'vue';

export default {
    name: 'UserManagement',
    components: {
        FullPageLoadingSpinner,
        InviteAdmin,
        TableAdmin,
        DeleteUsers,
        PageHeading,
        DeleteUsers,
        Button,
        Dialog,
        STabs,
        STab,
    },
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            breadcrumbs: [],
            isUploading: false,
            uploadProgress: 0,
            fileUploadError: '',
            isProcessingInvite: false,
            adminUsers: {},
            adminUserGroups: {},
            showInviteAdminDialog: false,
            LoadingFlag,
        };
    },
    computed: {
        showInviteAdminTab() {
            return config.featureToggle.inviteAdmin;
        },
    },
    async created() {
        this.breadcrumbs = [new Breadcrumb('User Management', { name: 'users' })];
        this.adminUsers = await this.getUsers();
    },
    methods: {
        ...mapActions('auth', ['signUp']),
        async getUsers() {
            const users = await this.$api.user.getUsers();
            if (!users) {
                return {};
            }
            return users.reduce((object, user) => {
                object[user.username] = user;
                return object;
            }, {});
        },
        async onSaveUser({ username, groups }) {
            const admin = await this.$api.user.updateUserGroups(username, groups);
            if (admin.groups) {
                Vue.set(this.adminUserGroups, username, admin.groups);
            }
        },
        async onLoadUser(username) {
            if (this.adminUserGroups[username]) {
                return;
            }
            const admin = await this.$api.user.getUser(username);
            if (admin.groups) {
                Vue.set(this.adminUserGroups, username, admin.groups);
            }
        },
        async onInviteAdmin(admin) {
            this.isProcessingInvite = true;
            if (admin && admin.email) {
                try {
                    await this.signUp(admin);
                    this.$toaster.add('Successfully created admin', { type: ToastType.Success });
                    this.showInviteAdminDialog = false;
                } catch (error) {
                    this.$toaster.add('User already exists');
                }
            }
            this.isProcessingInvite = false;
            this.adminUsers = await this.getUsers();
        },
    },
};
</script>

<style lang="scss" scoped>
.admin-toolbar {
    display: flex;
    justify-content: flex-end;
}
</style>
