<template>
    <Datatable
        :data="{ items: usersList }"
        :columns="$options.columns"
        :clickable-rows="true"
        class="table-admin"
        @rowClick="onClickRow"
    >
        <template v-slot:action="slotProps">
            <div class="align-right">
                <Icon
                    class="table-admin-row-toggle"
                    :class="{ 'table-admin-row-toggle--open': slotProps.item.hasDetails }"
                    name="arrow-down"
                />
            </div>
        </template>

        <template v-slot:details="{ item }">
            <div class="table-admin-row-details">
                <Tags
                    placeholder="Assign user to group"
                    label="User groups"
                    :value="userGroupTags[item.username] || []"
                    :autocomplete-items="$options.userGroups"
                    :autocomplete-min-length="0"
                    :separators="[' ', ';']"
                    :disabled="$wait.is(LoadingFlag.UserGetOne)"
                    @autocomplete="$emit('autocomplete', $event)"
                    @input="(groups) => onGroupInput(item.username, groups)"
                />
            </div>
        </template>
    </Datatable>
</template>

<script>
import Icon from '@/components/ui/Icon';
import { Tags } from '@/components/forms';
import { UserRoles } from '@/store/enums';
import Datatable from '@/components/Datatable';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';

const mapUsers = (user) => ({ ...user, hasDetails: false });

export default {
    name: 'TableAdmin',
    components: {
        Datatable,
        Tags,
        Icon,
    },
    props: {
        users: {
            type: Object,
            default: () => ({}),
        },
        userGroups: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            LoadingFlag,
            usersList: [],
        };
    },
    computed: {
        userGroupTags() {
            const tags = {};
            for (const user in this.userGroups) {
                tags[user] = this.userGroups[user].map((group) => ({ id: group, name: group }));
            }
            return tags;
        },
    },
    watch: {
        users: {
            immediate: true,
            handler(users) {
                this.usersList = Object.values(users).map(mapUsers);
            },
        },
    },
    created() {
        this.$options.columns = [
            {
                value: 'email',
                header: 'Email',
                type: 'text',
                width: '25%',
            },
            {
                value: 'username',
                header: 'Username',
                type: 'text',
                width: '40%',
            },
            {
                value: 'name',
                header: 'Name',
                type: 'text',
                width: '30%',
            },
            {
                value: 'action',
                header: '',
                type: 'slot',
                width: '5%',
            },
        ];
        this.$options.userGroups = [
            { name: UserRoles.Admin, id: UserRoles.Admin },
            { name: UserRoles.Editor, id: UserRoles.Editor },
            { name: UserRoles.Yougov, id: UserRoles.Yougov },
        ];
    },
    methods: {
        onGroupInput(username, groups) {
            this.$emit('save-user', { username, groups: groups.map((g) => g.id) });
        },
        onClickRow(event, item) {
            const userIdx = this.usersList.findIndex((user) => user.username === item.username);
            const hasDetails = this.usersList[userIdx].hasDetails;
            const list = [...this.usersList].map(mapUsers);
            list[userIdx].hasDetails = !hasDetails;
            this.usersList = list;
            if (list[userIdx].hasDetails) {
                this.$emit('load-user', item.username);
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-admin-row-details {
    padding: 2rem;

    &,
    &:hover {
        background-color: lighten($table-row-hover, 3%);
    }
}

.table-admin-row-toggle {
    font-size: $font-size-xs;

    &--open {
        transform: rotate(180deg);
    }
}
</style>
