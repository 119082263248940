<template>
    <form class="invite-admin" @submit.prevent="onSubmit">
        <Input
            id="admin-name"
            v-model="formData.name"
            name="admin-name"
            :max="152"
            type="text"
            label="Name"
            required
            :errors="errors.name || []"
            show-remaining-char
        />
        <Input
            id="admin-email"
            v-model="formData.email"
            name="admin-email"
            :max="152"
            type="text"
            label="Email"
            required
            :errors="errors.email || []"
            show-remaining-char
        />
        <Button
            class="invite-admin__button"
            has-border
            size="small"
            :disabled="!isValid"
            type="submit"
            :is-loading="isProcessingInvite"
        >
            Invite Admin
        </Button>
    </form>
</template>
<script>
import Input from '@/components/forms/Input';
import Button from '@/components/ui/Button';
import { isEmail } from 'class-validator';

const defaultState = () => ({
    formData: {
        name: '',
        email: '',
    },
    errors: {},
});

export default {
    name: 'InviteAdmin',
    components: {
        Input,
        Button,
    },
    props: {
        isProcessingInvite: {
            type: Boolean,
            default: false,
        },
    },
    data: () => defaultState(),
    computed: {
        isValid() {
            return Object.keys(this.errors).length === 0;
        },
    },
    watch: {
        formData: {
            deep: true,
            handler() {
                this.validate();
            },
        },
    },
    methods: {
        validate() {
            this.errors = {};
            const { email, name } = this.formData;
            if (name === '') {
                this.errors.name = ['Please enter a name'];
            }
            if (email === '' || !isEmail(email)) {
                this.errors.email = ['Please enter valid email address'];
            }
        },
        onSubmit(event) {
            this.$emit('invite-admin', this.formData);
        },
    },
};
</script>
<style lang="scss" scoped>
.invite-admin {
    display: flex;
    flex-direction: column;

    &-text {
        margin-top: $form-group-margin-top;
        font-family: $font-family-default;
        font-size: $font-size-sm;
        font-weight: $font-weight-default;
    }
    &__button {
        margin-top: $form-group-margin-top;
        align-self: flex-end;
    }
}
</style>
